import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {StaffFetchDetailsComponent} from './utils/ufls/staff-fetch-details/staff-fetch-details.component';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from "./pages/home/home.component";
import {IonicStorageModule} from "@ionic/storage-angular";
import {HttpClientModule} from "@angular/common/http";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {AttendeesComponent} from "./pages/attendees/attendees.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {AttendeeDetailComponent} from "./models/attendee-detail/attendee-detail.component";
import {SummonComponent} from "./models/summon/summon.component";
import {FormsModule} from "@angular/forms";
import {CheckInComponent} from "./models/check-in/check-in.component";
import {CreepComponent} from "./models/creep/creep.component";
import {RegStationNumberComponent} from "./models/reg-station-number/reg-station-number.component";
import {PictureWallComponent} from "./pages/picture-wall/picture-wall.component";
import {AccessibilityComponent} from "./models/accessibility/accessibility.component";
import {ChartjsModule} from "@ctrl/ngx-chartjs";
import {
  BarController,
  BarElement,
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  PointElement,
  LineElement,
  PieController,
  ArcElement
} from 'chart.js';
import {EditAttendeeNotesComponent} from "./models/edit-attendee-notes/edit-attendee-notes.component";
import {CancelAttendeeRegComponent} from "./models/cancel-attendee-reg/cancel-attendee-reg.component";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, LineController, PointElement, LineElement, PieController, ArcElement);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        StaffFetchDetailsComponent,
        HomeComponent,
        AttendeesComponent,
        SettingsComponent,
        AttendeeDetailComponent,
        SummonComponent,
        CheckInComponent,
        CreepComponent,
        EditAttendeeNotesComponent,
        RegStationNumberComponent,
        CancelAttendeeRegComponent,
        PictureWallComponent,
        AccessibilityComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ mode: 'ios' }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ChartjsModule,
        NgxDatatableModule.forRoot({
            messages: {
                emptyMessage: 'No data to display',
                totalMessage: 'total',
                selectedMessage: 'selected' // Footer selected message
            }
        }),
        FormsModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule {}
