// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1/-1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}

.ct {
  column-count: 4;
  column-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/picture-wall/picture-wall.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;EACA,4BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,0CAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":["img {\n  max-width: 100%;\n  display: block;\n}\n\nfigure {\n  margin: 0;\n  display: grid;\n  grid-template-rows: 1fr auto;\n  margin-bottom: 10px;\n  break-inside: avoid;\n}\n\nfigure > img {\n  grid-row: 1 / -1;\n  grid-column: 1;\n}\n\nfigure a {\n  color: black;\n  text-decoration: none;\n}\n\nfigcaption {\n  grid-row: 2;\n  grid-column: 1;\n  background-color: rgba(255,255,255,.5);\n  padding: .2em .5em;\n  justify-self: start;\n}\n\n.ct {\n  column-count: 4;\n  column-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
