import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";
import {Registrant} from "../../interfaces/registrant";
import {CheckInComponent} from "../check-in/check-in.component";
import {CreepComponent} from "../creep/creep.component";
import {ForwardUserComponent} from "../forward-user/forward-user.component";
import {AccessibilityComponent} from "../accessibility/accessibility.component";
import {StorageService} from "../../services/storage.service";
import {EditAttendeeNotesComponent} from "../edit-attendee-notes/edit-attendee-notes.component";
import {CancelAttendeeRegComponent} from "../cancel-attendee-reg/cancel-attendee-reg.component";

@Component({
  selector: 'app-attendee-detail',
  templateUrl: './attendee-detail.component.html',
  styleUrls: ['./attendee-detail.component.scss'],
})
export class AttendeeDetailComponent implements OnInit {

  fullyLoaded: boolean = false;

  constructor(
    private modalController: ModalController,
    private rest: RestService,
    private storage: StorageService
  ) {}

  @Input() attendeeId: string;
  @Input() regStationNumber: string;
  attendee: Registrant;

  ngOnInit() {
    console.log(this.attendeeId);
    this.rest.getResults("registrants/"+this.attendeeId+"/")
      .subscribe(
        (res) => {
          this.attendee = res;
          this.storage.selectedAttendee = this.attendee;
          this.fullyLoaded = true;
        }
      )
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  loadValue(state: string) {
    //console.table(this.attendee.fieldData);
    // TODO(phillypu): attendee.fieldData is required to be Array, but no check exists for this.
    //   In addition, Registrant interface lists fieldData as "any" and optional.
    for (let i = 0; i < this.attendee.fieldData.length; i++) {
      if(this.attendee.fieldData[i].path === state){
        return this.attendee.fieldData[i]['value']
      }
    }
  }

  async checkIn() {
    const modal = await this.modalController.create({
      component: CheckInComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee,
        "regStationNumber": this.regStationNumber
      },
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }

  async showCreepWindow() {
    const modal = await this.modalController.create({
      component: CreepComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee,
        "regStationNumber": this.regStationNumber,
      },
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }

  removeCreepFlag() {
    delete this.attendee.registrationQrCode;
    this.rest.patch("registrants/"+this.attendee.id+"/", {"conCreepFlag": false})
      .subscribe(
        (res) => {
          this.ngOnInit();
        },
        (err) => {
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }

  async loadForward() {
    const modal = await this.modalController.create({
      component: ForwardUserComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }

  async startAccessibility() {
    const modal = await this.modalController.create({
      component: AccessibilityComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }

  doDetachBadge() {
    this.rest.getResults("functions/detachBadge/"+this.attendee.getBadgeNumber+"/")
      .subscribe(
        (res) => {
          alert("Badge Detached Successfully.");
          this.ngOnInit();
        },
        (err) => {
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }

  async loadNotes() {
    const modal = await this.modalController.create({
      component: EditAttendeeNotesComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }

  async loadCancellation() {
    const modal = await this.modalController.create({
      component: CancelAttendeeRegComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendee": this.attendee
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );
  }
}
