import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {HomeComponent} from "./pages/home/home.component";
import {StaffFetchDetailsComponent} from "./utils/ufls/staff-fetch-details/staff-fetch-details.component";
import {AttendeesComponent} from "./pages/attendees/attendees.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {PictureWallComponent} from "./pages/picture-wall/picture-wall.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'attendees',
    component: AttendeesComponent
  },
  {
    path: 'wall',
    component: PictureWallComponent
  },
  {
    path: 'attendees/filter/:param',
    component: AttendeesComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'ufls/staff',
    component: StaffFetchDetailsComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
