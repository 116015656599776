import {Component, Input, OnInit} from '@angular/core';
import {Registrant} from "../../interfaces/registrant";
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-creep',
  templateUrl: './creep.component.html',
  styleUrls: ['./creep.component.scss'],
})
export class CreepComponent implements OnInit {

  attendee: Registrant;
  whatsUp = "";

  constructor(
    private modalController: ModalController,
    private rest: RestService,
    private storage: StorageService,
  ) {

  }

  ngOnInit() {
    this.attendee = this.storage.selectedAttendee;
    console.log(this.attendee, this.storage.selectedAttendee);
  }

  sendFlag() {
    console.log(this.attendee);
    this.rest.patch("registrants/"+this.attendee.id+"/", {"conCreepFlag": true, "conRegNotes": this.attendee.conRegNotes + "\nFLAG: "+this.whatsUp})
      .subscribe(
        (res) => {
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }

  attendeeName() {
    let attendee = this.storage.selectedAttendee;
    return (attendee)
      ? `${ attendee.conFirstName } ${ attendee.conLastName } (${ attendee.conBadgeName })`
      : null;
  }
}
