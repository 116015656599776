import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RegistrantGlimpse} from "../interfaces/registrant-glimpse";
import {Event} from "../interfaces/event";
import moment from "moment/moment";
import {Router} from "@angular/router";
import {AlertController, ToastController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class RestService {
  glimpseList: RegistrantGlimpse[];
  timerFunc: any;

  event: Event;
  lastFetchFromServer: string;

  getResults(uri: string): Observable<any>{
    return this.getWithParams(uri, {});
  }

  getWithParams(uri: string, params: object): Observable<any> {
    let httpParams = new HttpParams({fromObject: {...params}});

    return this.http.get(environment.baseUrl+uri, {
      params: httpParams,
      headers: {
        Authorization: 'Token ' + environment.resultsAuthToken
      }
    })
  }

  postWithParams(uri: string, data: any, params: object): Observable<any> {
    let httpParams = new HttpParams({fromObject: {...params}});

    return this.http.post(environment.baseUrl+uri, data, {
      params: httpParams,
      headers: {
        Authorization: 'Token ' + environment.resultsAuthToken
      }
    })
  }

  post(uri: string, data: any): Observable<any> {
    return this.http.post(environment.baseUrl+uri, data, {
      headers: {
        Authorization: 'Token '+environment.resultsAuthToken
      }
    })
  }
  patch(uri: string, data: any): Observable<any> {
    return this.http.patch(environment.baseUrl+uri, data, {
      headers: {
        Authorization: 'Token '+environment.resultsAuthToken
      }
    })
  }

  reloadSystem(){
    this.getResults('events/'+environment.eventPk+'/')
      .subscribe(
        (res) => {
          this.event = res;
          this.event.timeLastSync = moment(this.event.timeLastSync).format("MM/DD/YYYY HH:mm:ss");
          this.lastFetchFromServer = moment().format("MM/DD/YYYY HH:mm:ss")
        },
        (err) => {
          this.router.navigate(['/login'])
        }
      )
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'New Registrants have been Added',
      message: 'New Data is available from Ply. Refresh your page to update your dataset.',
      buttons: [
        {
          text: 'Not Yet',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Refresh',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }

  constructor(
    private http: HttpClient,
    public router: Router,
    public alertController: AlertController,
    public toastController: ToastController
  ) {
    /**
    setInterval(() => {
      this.getResults('events/'+environment.eventPk+'/')
        .subscribe(
          (res) => {
            if(moment(res.timeLastSync).format("MM/DD/YYYY HH:mm:ss") !== this.event.timeLastSync){
              this.presentToast("New Registrant Data has been Imported into Ply. Refresh your dataset when you have a moment.")
            }
          },
          (err) => {
            this.router.navigate(['/login'])
          }
        )
    }, 120000); **/
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }




}
