import {Component, Input, OnInit} from '@angular/core';
import {Registrant} from "../../interfaces/registrant";
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";

@Component({
  selector: 'app-forward-user',
  templateUrl: './forward-user.component.html',
  styleUrls: ['./forward-user.component.scss'],
})
export class ForwardUserComponent implements OnInit {

  @Input() attendee: Registrant;

  constructor(
    private modalController: ModalController,
    private rest: RestService
  ) { }

  ngOnInit() {}

  commit() {
    this.rest.patch("registrants/"+this.attendee.id+"/", {"isForwarding": true, "conRegNotes": "Requested forwarding to next year."})
      .subscribe(
        (res) => {
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }
}
