import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {getDisplayedRegStationNumber, NOT_A_REG_STATION} from './reg-station-number-utils';

@Component({
  selector: 'app-reg-station-number',
  templateUrl: './reg-station-number.component.html',
  styleUrls: ['./reg-station-number.component.scss'],
})
export class RegStationNumberComponent implements OnInit {
  regNumber = null;
  validRegNumbers = [NOT_A_REG_STATION, '1', '2', '3', '4', '5'];

  @Input() oldRegStationNumber: string;

  constructor(
    private modalController: ModalController,
  ) {}

  async ngOnInit() {
    this.regNumber = this.oldRegStationNumber;
    console.log('Retrieved regNumber: ' + this.regNumber);
  }

  updateRegStationNumber(newNum: string) {
    this.regNumber = (newNum === NOT_A_REG_STATION)
      ? null
      : newNum;
  }

  dismissModal() {
    this.modalController.dismiss(this.regNumber);
  }

  getRegNumber() {
    return getDisplayedRegStationNumber(this.regNumber);
  }

}
