import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  processLogin: boolean;

  constructor(
    private nav: NavigationService
  ) {
    this.processLogin = false;
  }

  ngOnInit() {

  }

  beginLogin() {
    this.processLogin = true;
  }

  protected readonly environment = environment;
}
