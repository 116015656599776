// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caution-bg {
  --background: linear-gradient(45deg, #000000 25%, #7d7500 25%, #7d7500 50%, #000000 50%, #000000 75%, #7d7500 75%, #7d7500 100%) !important;
  background-size: 56.57px 56.57px !important;
  color: red;
  font-weight: bolder;
  text-decoration: underline;
}

.caution-bg-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/models/summon/summon.component.scss"],"names":[],"mappings":"AAAA;EACE,2IAAA;EACA,2CAAA;EACA,UAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AACA;EACE,qCAAA;AAEF;;AAAA;EACE;IACE,UAAA;EAGF;AACF","sourcesContent":[".caution-bg {\n  --background: linear-gradient(45deg, #000000 25%, #7d7500 25%, #7d7500 50%, #000000 50%, #000000 75%, #7d7500 75%, #7d7500 100%) !important;\n  background-size: 56.57px 56.57px !important;\n  color: red;\n  font-weight: bolder;\n  text-decoration: underline;\n}\n.caution-bg-text {\n  animation: blinker 1s linear infinite;\n}\n@keyframes blinker {\n  50% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
