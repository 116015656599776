import { Injectable } from '@angular/core';
import {User} from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  activeUser: User;
  loggedIn = false;
  loginChecked: boolean = true;

  constructor() {
    this.activeUser = JSON.parse(localStorage.getItem('fdregui-activeUser')) as unknown as User || undefined;
    let ts = localStorage.getItem('fdregui-timeStamp');

    if (this.activeUser) {
      this.loggedIn = true;
    }
    this.loginChecked = true;
  }
}
