import {Component, Input, OnInit} from '@angular/core';
import {Registrant} from "../../interfaces/registrant";
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-cancel-attendee-reg',
  templateUrl: './cancel-attendee-reg.component.html',
  styleUrls: ['./cancel-attendee-reg.component.scss'],
})
export class CancelAttendeeRegComponent  implements OnInit {
  @Input() attendee: Registrant;
  whatsUp = "";

  constructor(
    private modalController: ModalController,
    private rest: RestService,
    private storage: StorageService,
  ) {
    this.attendee = this.storage.selectedAttendee;
    console.log(this.storage.selectedAttendee);
    this.whatsUp = this.attendee.conRegNotes;
  }

  ngOnInit() {}

  sendFlag() {
    this.rest.patch("registrants/"+this.attendee.id+"/", {"status": "cancelled"})
      .subscribe(
        (res) => {
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }

  attendeeName() {
    let attendee = this.attendee;
    return (attendee)
      ? `${ attendee.conFirstName } ${ attendee.conLastName } (${ attendee.conBadgeName })`
      : null;
  }

}
