import {Component, OnInit} from '@angular/core';
import {Route, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import moment from 'moment/moment';

import {StorageService} from './services/storage.service';
import {AuthService} from './services/auth.service';
import {RestService} from './services/rest.service';
import {SummonComponent} from './models/summon/summon.component';
import {RegStationNumberComponent} from './models/reg-station-number/reg-station-number.component';
import {getDisplayedRegStationNumber} from './models/reg-station-number/reg-station-number-utils';
import {REG_STATION_NUMBER_KEY} from './utils/constants';
import {environment} from "../environments/environment";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public regStationNumber = null;

  constructor(
    private storage: StorageService,
    public auth: AuthService,
    public rest: RestService,
    public router: Router,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    if(this.auth.loginChecked && this.auth.loggedIn){
      this.rest.reloadSystem();
      await this.storage.init();

      this.regStationNumber = await this.retrieveRegStationNumber();
      if (this.regStationNumber === null) {
        // prompt modal for setting regStationNumber if it's currently unset
        return await this.regStationNumberModal();
      }
    }
  }

  async summon() {
    const modal = await this.modalController.create({
      component: SummonComponent,
      cssClass: 'my-custom-class',
      componentProps: { }
    });
    return await modal.present();
  }

  async regStationNumberModal() {
    const regStationNumberModal = await this.modalController.create({
      component: RegStationNumberComponent,
      cssClass: 'my-custom-class',
      componentProps: { 'oldRegStationNumber': this.regStationNumber },
      backdropDismiss: false,
    });

    // update the reg station number once the modal's been dismissed
    regStationNumberModal.onWillDismiss<string>()
      .then((event) => {
	      this.updateRegStationNumber(event.data)
      });

    return await regStationNumberModal.present();
  }

  /**
  * Retrieves regStationNumber from cookies/storage
  */
  async retrieveRegStationNumber() {
    let regStationNumber = await this.storage.get(REG_STATION_NUMBER_KEY);
    return regStationNumber;
  }

  /**
  * Takes the input and populates the cookie/db with it as regStationNumber
  */
  async updateRegStationNumber(newRegStationNumber : string) {
    this.regStationNumber = newRegStationNumber;
    this.storage.set(REG_STATION_NUMBER_KEY, this.regStationNumber);

    return this.regStationNumber;
  }

  getRegNumber() {
    return getDisplayedRegStationNumber(this.regStationNumber);
  }

  doLogout() {
    this.auth.activeUser = undefined;
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  protected readonly environment = environment;
}
