import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { Event } from "../interfaces/event";
import {Registrant} from "../interfaces/registrant";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  selectedAttendee: Registrant;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can call:
  public get(key: string) {
    return this._storage.get(key);
  }

  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }
}
