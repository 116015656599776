import {Component, OnInit, ViewChild} from '@angular/core';
import {RegistrantGlimpse} from "../../interfaces/registrant-glimpse";
import {RestService} from "../../services/rest.service";
import {ModalController, ToastController} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {StorageService} from "../../services/storage.service";
import {environment} from "../../../environments/environment";
import {REG_STATION_NUMBER_KEY} from "../../utils/constants";
import {AttendeeDetailComponent} from "../../models/attendee-detail/attendee-detail.component";

@Component({
  selector: 'app-picture-wall',
  templateUrl: './picture-wall.component.html',
  styleUrls: ['./picture-wall.component.scss'],
})
export class PictureWallComponent implements OnInit {

  columns = [
    { name: 'First Name', prop: 'conFirstName' },
    { name: 'Last Name', prop: 'conLastName' },
    { name: 'E-mail', prop: 'conEmail' },
    { name: 'DOB', prop: 'conDOB' },
    { name: 'Badge Name', prop: 'conBadgeName' },
    { name: 'Reg Level', prop: 'conRegLevel' },
    { name: 'Checked In', prop: 'conCheckedIn' }
  ];

  @ViewChild('myTable') table: any;

  regGlimpse: RegistrantGlimpse[] = [];
  loadingIndicator = true;

  constructor(
    private rest: RestService,
    public toastController: ToastController,
    public modalController: ModalController,
    private router: ActivatedRoute,
    private storage: StorageService,
  ) {

  }

  reorderable = true;

  param = "";

  getList(filter: string = ""){
    this.rest.getResults("registrants-glimpse/?"+filter+'&event='+environment.eventPk+'&status=completed')
      .subscribe(
        (res) => {
          console.log(res);
          this.temp = [...res]
          this.regGlimpse = res;
          this.loadingIndicator = false;
        },
        (err) => {
          console.log(err);
        }
      )
  }

  ngOnInit() {

    this.param = "";

    let filter = this.router.params.subscribe(
      (res) => {
        if(res.param === "conCreepFlag"){
          console.log(res.param);
          this.param = "Creep Flag";
          this.getList(res.param+"=true");
        }else if(res.param === "conCheckedIn") {
          console.log(res.param);
          this.param = "Checked In";
          this.getList(res.param + "=true");
        }else if(res.param === "conCheckedIn,hasMealPlan"){
          this.param = "Has Meal Plan & Is Checked In"
          this.getList("conCheckedIn=true&hasMealPlan=true");
        }else if(res.param === "isForwarding"){
          this.param = "Has been Forwarded to Next Year"
          this.getList("isForwarding=true");
        }else{
          this.getList();
        }

      },
      (err) => {
        console.log(err);
      }
    )


  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  async launchRegModal(pk: string) {
    let regStationNumber = await this.storage.get(REG_STATION_NUMBER_KEY);

    const modal = await this.modalController.create({
      component: AttendeeDetailComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        "attendeeId": pk,
        "regStationNumber": regStationNumber,
      }
    });
    return await modal.present();
  }

  temp = [];

  reloadSystem() {
    this.presentToast("Please wait, we're fetching from the server.")
    this.regGlimpse = [];
    this.rest.reloadSystem();
    this.getList();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }
}
