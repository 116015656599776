import {Component, Input, OnInit} from '@angular/core';
import {Registrant} from "../../interfaces/registrant";
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
})
export class AccessibilityComponent implements OnInit {
  @Input() attendee: Registrant;
  step: number = 1;

  constructor(
    private modalController: ModalController,
    private rest: RestService
  ) { }

  ngOnInit() {
    console.log(this.attendee);
  }

  handleQ1Change($event: CustomEvent) {
    console.log($event);
    let n = $event.detail.value;
    switch (n) {
      case 'yes-full':
        this.attendee.a11yFullAssist = true;
        this.attendee.a11yPartialAssist = false;
        break;
      case 'yes-part':
        this.attendee.a11yPartialAssist = true;
        this.attendee.a11yFullAssist = false;
        break;
      case 'no':
        this.attendee.a11yFullAssist = false;
        this.attendee.a11yPartialAssist = false;
        break;
    }
  }

  enroll() {

    this.attendee.accessibility = true;
    delete this.attendee.registrationQrCode;
    this.rest.patch("registrants/"+this.attendee.id+"/", this.attendee)
      .subscribe(
        (res) => {
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
          alert("Critical App Error Occured. Please contact Yuukari.");
        }
      )
  }
}
