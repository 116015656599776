import { Component, OnInit } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { RestService } from "../../services/rest.service";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  constructor(
    public alertController: AlertController,
    private rest: RestService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    if(this.auth.loginChecked && !this.auth.loggedIn) {
      this.router.navigate(['/login']);
    }
  }

  async presentAlertSync(ret: any) {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: ret.status,
      message: ret.message,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            if(ret.status === 'success'){
              window.location.reload();
            }
          }
        }
      ]
    });
    alert.present();
  }

  issueRegfoxSync() {
    this.rest.getResults('functions/syncRegfox/')
      .subscribe(
        (res) => {
          this.presentAlertSync(res);
        },
        (err) => {
          console.log(err);
        }
      )
  }

  printBadgeBack() {
    this.rest.getResults('badge/print-back/')
      .subscribe(
        (res)=>{
          this.rest.presentToast("Sent to Print Queue")
        },
        (err) => {
          this.rest.presentToast("An error occured. Check logs.");
          console.log(err);
        }
      )
  }
  printLunch() {
    this.rest.getResults('badge/print-lunch/')
      .subscribe(
        (res)=>{
          this.rest.presentToast("Sent to Print Queue")
        },
        (err) => {
          this.rest.presentToast("An error occured. Check logs.");
          console.log(err);
        }
      )
  }
}
