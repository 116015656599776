import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {RestService} from "../../services/rest.service";

@Component({
  selector: 'app-summon',
  templateUrl: './summon.component.html',
  styleUrls: ['./summon.component.scss'],
})
export class SummonComponent implements OnInit {

  whatsUp = "";

  package = {
    "to": "",
    "message": ""
  }

  constructor(
    private modalController: ModalController,
    private rest: RestService
  ) {
    this.package = {
      "to": "",
      "message": ""
    }
  }

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  initSummon(person: string) {
    this.package.to = person;

    this.rest.post('functions/ping/', this.package)
      .subscribe(
        (res) => {
          this.rest.presentToast("Sent message to "+person)
          this.modalController.dismiss();
        },
        (err) => {
          console.log(err);
        }
      )

  }
}
