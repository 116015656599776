export const NOT_A_REG_STATION = "Not a reg station";

/**
* Given a raw reg station number, return a friendlier reg station number
*
* All this means is that if it's null, return "Not a reg station".
*/
export function getDisplayedRegStationNumber(regStationNumber: string) {
  return (regStationNumber === null)
    ? NOT_A_REG_STATION
    : regStationNumber;
}
